<template>
  <div class="rust-and-sensor _flex _font-weight__600">
    <!-- rust -->
    <div class="rust-box">
      <div class="title-box">
        <div class="title-box__icon">
          <img src="../../../assets/icons/dashboard/Rust.png" />
          <span>Rust</span>
        </div>
      </div>

      <div class="rust-box__content">
        <div class="rust-box__content__text" data-label="Rust detected">
          {{ resolution(detail, 'rustDetectedCount') }}
        </div>

        <el-button
          type="info"
          @click="
            $router.push({
              path: '/analysis',
              query: {
                tabsIndex: 0,
                time
              }
            })
          "
          >Show More</el-button
        >
      </div>
    </div>

    <!-- tilt sensor -->
    <div class="tilt-sensor">
      <div class="title-box">
        <div class="title-box__icon">
          <img src="../../../assets/icons/dashboard/Tilt Sensor.png" />
          <span>Tilt Sensor</span>
        </div>
        <el-button
          type="info"
          @click="$router.push({ path: '/data', query: { tabsIndex: 0, time } })"
        >
          Show More
        </el-button>
      </div>

      <div class="tilt-sensor__content">
        <div
          class="tilt-sensor__content__item _font-size__heading2 _flex _flex-justify__between"
          v-for="item in sersors"
          :key="item.axle"
        >
          <span class="tilt-sensor__item__axle" :data-axle="item.axle">R</span>
          <span>{{ resolution(detail, 'latelyTiltSensorData.' + item.key) }}°</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { props } from './props'

export default {
  props,
  data() {
    return {
      sersors: [
        {
          axle: 'x',
          key: 'rx'
        },
        {
          axle: 'y',
          key: 'ry'
        },
        {
          axle: 'z',
          key: 'rz'
        }
      ]
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.rust-and-sensor {
  .rust-box,
  .tilt-sensor {
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    background: var(--color-white);
  }

  .rust-box {
    width: 185px;
    margin-right: 20px;
    &__content {
      width: 150px;
      font-size: 40px;
      margin-right: 20px;
      &__text {
        margin-bottom: 25px;
        &::after {
          content: attr(data-label);
          display: block;
          font-size: 15px;
          margin-top: 13px;
        }
      }
    }
  }

  .tilt-sensor {
    flex: 1;
    &__content {
      &__item {
        width: 100%;
        margin-bottom: 15px;
        padding: 5px 0 10px 0;
        border-bottom: 1px solid var(--color-info-light-7);

        &:last-of-type {
          border-bottom: none;
          margin-bottom: 0;
        }
      }
    }

    &__item__axle {
      padding-left: 5px;
      &::after {
        content: attr(data-axle);
        display: inline-block;
        font-size: 14px;
        transform: translateY(3px);
      }
    }
  }
}
</style>
